<template>
  
  <div @click="startNewListing()" class="new-post-button">
    <!-- <div class="npb-content">
      <span class="npb-icon"><font-awesome-icon icon="location-arrow" /></span>
      <span class="npb-text">Sell an Item</span>
    </div> -->
    <div class="npb-content">
      <!-- <span class="npb-icon"><font-awesome-icon icon="location-arrow" /></span> -->
      <span class="npb-text">+</span>
    </div>
  </div>


</template>






<script>

  import NProgress from 'nprogress';

  export default {

    components: {
      NProgress,
    },
    data() {
      return {
        showNewPostButton: true,
      }
    },

    methods: {

      startNewListing() {
        this.$router.push({ 
          name: 'NewListingStart', 
          params: { 
            //
          } 
        });

      },



    }

  }
  

</script>



<style lang="scss" scoped>
  
  .new-post-button {
    position: fixed;
    bottom: 145px;
    right: 20px;
    // width: 120px;
    // height: 120px;
    width: 80px;
    height: 80px;
    background: #0ae08e;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    z-index: 99;
    .npb-content {
      color: #fff;
      height: 100%;
      position: relative;
      .npb-icon {
        display: block;
        margin: 17px auto 1px;
        display: block;
        font-size: 1.2rem;
      }
      .npb-text {
        font-weight: bold;
        // font-size: 1.4rem;
        // line-height: 1.5rem;
        display: block;
        font-size: 38px;
        line-height: 48px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
      
    &:hover {
      // background: #40f178;
      transform: scale(1.15);
    }
  }

   @media only screen and (max-width: 600px) {

    .new-post-button {
      bottom: 75px;
    }

  }    

  @media only screen and (max-width: 479px) {

    .new-post-button {
      // bottom: 10px;
      // left: 10px;
      // width: 100px;
      // height: 100px;
      bottom: 70px;
      right: 10px;
      width: 50px;
      height: 50px;
      .npb-content {
        padding: 0 10px;
        .npb-icon {
          font-size: 1rem;
          margin-top: 12px;
        }
        .npb-text {
          // font-size: 1.1rem;
          // line-height: 1.3rem;
          font-size: 38px;
          line-height: 48px;
        }
      }
      &:hover {
        transform: none;
      }      

    }

  }    


</style>







